import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'money_circle_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="money_circle_outline_24"><path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 1.8a9.2 9.2 0 100 18.4 9.2 9.2 0 000-18.4zM13.253 7a3.357 3.357 0 110 6.714H11V15h1.5a.75.75 0 010 1.5H11v.65a.85.85 0 01-1.7 0l-.001-.65H8.5a.75.75 0 010-1.5h.799v-1.286h-.692a.857.857 0 010-1.714H9.3L9.3 7.85c0-.47.38-.85.85-.85h3.103zm.033 1.571H11V12h2.286a1.714 1.714 0 100-3.429z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MoneyCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MoneyCircleOutline: FC<Icon24MoneyCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MoneyCircleOutline as any).mountIcon = mountIcon;

export default Icon24MoneyCircleOutline;
