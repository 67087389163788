import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'smartphone_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="smartphone_outline_24"><path clip-rule="evenodd" d="M11.18 3.9h1.64c1.094 0 1.806.001 2.35.046.522.042.728.116.838.172a2 2 0 01.874.874c.056.11.13.316.172.839.045.543.046 1.255.046 2.349v7.64c0 1.094-.001 1.806-.046 2.35-.043.522-.116.728-.172.838a2 2 0 01-.874.874c-.11.056-.316.13-.839.172-.543.045-1.255.046-2.349.046h-1.64c-1.094 0-1.806-.001-2.35-.046-.522-.043-.728-.116-.838-.172a2 2 0 01-.874-.874c-.056-.11-.13-.316-.172-.839-.045-.543-.046-1.255-.046-2.349V8.18c0-1.094.001-1.806.046-2.35.042-.522.116-.728.172-.838a2 2 0 01.874-.874c.11-.056.316-.13.839-.172.543-.045 1.255-.046 2.349-.046zM5.1 8.18c0-2.128 0-3.192.414-4.005a3.8 3.8 0 011.66-1.66C7.989 2.1 9.053 2.1 11.18 2.1h1.64c2.128 0 3.192 0 4.005.414a3.8 3.8 0 011.66 1.66c.415.814.415 1.878.415 4.006v7.64c0 2.128 0 3.192-.414 4.005a3.8 3.8 0 01-1.66 1.66c-.814.415-1.878.415-4.006.415h-1.64c-2.128 0-3.192 0-4.005-.414a3.8 3.8 0 01-1.66-1.66C5.1 19.011 5.1 17.947 5.1 15.82zm6.15 8.02a.9.9 0 100 1.8h1.5a.9.9 0 000-1.8z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SmartphoneOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24SmartphoneOutline: FC<Icon24SmartphoneOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24SmartphoneOutline as any).mountIcon = mountIcon;

export default Icon24SmartphoneOutline;
