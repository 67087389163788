import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'note_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="note_32"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.333 3c-2.609 0-3.799.222-5.008.868a5.923 5.923 0 00-2.457 2.457C3.222 7.535 3 8.725 3 11.333v9.334c0 2.609.222 3.799.868 5.008a5.923 5.923 0 002.457 2.457c1.21.646 2.4.868 5.008.868h9.334c2.609 0 3.799-.222 5.008-.868a5.923 5.923 0 002.457-2.457c.646-1.21.868-2.4.868-5.008v-9.334c0-2.609-.222-3.799-.868-5.008a5.923 5.923 0 00-2.457-2.457c-1.21-.646-2.4-.868-5.008-.868h-9.334zM10.25 9a1.25 1.25 0 100 2.5h11.5a1.25 1.25 0 100-2.5h-11.5zM9 16.25c0-.69.56-1.25 1.25-1.25h11.5a1.25 1.25 0 110 2.5h-11.5c-.69 0-1.25-.56-1.25-1.25zM10.25 21a1.25 1.25 0 100 2.5h7.5a1.25 1.25 0 100-2.5h-7.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32NoteProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Note: FC<Icon32NoteProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Note as any).mountIcon = mountIcon;

export default Icon32Note;
