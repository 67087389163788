import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'discount_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="discount_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.64 3.002a3.28 3.28 0 014.718 0l.76.787a1.28 1.28 0 001.055.384l1.089-.114a3.28 3.28 0 013.614 3.032l.076 1.092c.028.393.235.752.561.972l.908.612a3.28 3.28 0 01.819 4.646l-.643.886a1.28 1.28 0 00-.195 1.105l.301 1.052a3.28 3.28 0 01-2.359 4.086l-1.062.265a1.28 1.28 0 00-.859.72l-.446 1a3.28 3.28 0 01-4.433 1.614l-.984-.48a1.28 1.28 0 00-1.122 0l-.984.48a3.28 3.28 0 01-4.433-1.613l-.445-1a1.28 1.28 0 00-.86-.721l-1.062-.265a3.28 3.28 0 01-2.359-4.086l.302-1.052a1.28 1.28 0 00-.195-1.105l-.643-.886a3.28 3.28 0 01.82-4.646l.907-.612a1.28 1.28 0 00.56-.972l.077-1.092A3.28 3.28 0 018.737 4.06l1.089.114c.391.041.78-.1 1.054-.384l.76-.787zm3.28 1.39a1.28 1.28 0 00-1.841 0l-.761.787a3.28 3.28 0 01-2.7.983l-1.09-.114a1.28 1.28 0 00-1.41 1.183l-.077 1.093a3.28 3.28 0 01-1.437 2.489l-.907.612a1.28 1.28 0 00-.32 1.813l.644.886a3.28 3.28 0 01.499 2.83l-.302 1.053a1.28 1.28 0 00.92 1.594l1.063.265a3.28 3.28 0 012.202 1.848l.445 1a1.28 1.28 0 001.73.63l.984-.48a3.28 3.28 0 012.874 0l.985.48a1.28 1.28 0 001.73-.63l.445-1a3.28 3.28 0 012.202-1.848l1.062-.265a1.28 1.28 0 00.92-1.594l-.3-1.053a3.28 3.28 0 01.498-2.83l.643-.886a1.28 1.28 0 00-.32-1.813l-.907-.613a3.28 3.28 0 01-1.437-2.488l-.076-1.093a1.28 1.28 0 00-1.41-1.183l-1.09.114a3.28 3.28 0 01-2.7-.983l-.761-.787z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M11.67 11.5V11a.67.67 0 00-1.34 0v.5a.67.67 0 001.34 0zM11 9a2 2 0 00-2 2v.5a2 2 0 104 0V11a2 2 0 00-2-2zM17.67 17v-.5a.67.67 0 00-1.34 0v.5a.67.67 0 001.34 0zM17 14.5a2 2 0 00-2 2v.5a2 2 0 104 0v-.5a2 2 0 00-2-2zM17.111 9.192c.309.2.397.61.197.92l-5.5 8.5a.665.665 0 01-1.116-.723l5.5-8.5a.665.665 0 01.92-.197z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DiscountOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28DiscountOutline: FC<Icon28DiscountOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28DiscountOutline as any).mountIcon = mountIcon;

export default Icon28DiscountOutline;
