import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'gift_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="gift_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M21 10v2a1 1 0 01-1 1h-1v6.5a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 015 19.5V13h1-2a1 1 0 01-1-1v-2a2 2 0 012-2h14a2 2 0 012 2zm-8 3h6v2h-6v7h-2v-7H5v-2h6V8h2v5zm-1-5C9 8 5.5 7 5.5 5.5s2-3 3.5-2.5c1 .333 2 2 3 5 1-3 2-4.667 3-5 1.5-.5 3.5 1 3.5 2.5S15 8 12 8z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24GiftProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Gift: FC<Icon24GiftProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Gift as any).mountIcon = mountIcon;

export default Icon24Gift;
