import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'search_slash_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="search_slash_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.75 5.3c-.35 0-.69.033-1.02.095a.9.9 0 01-.334-1.769 7.25 7.25 0 018.476 8.485.9.9 0 11-1.768-.336A5.45 5.45 0 0010.75 5.3zM3.5 10.75a7.25 7.25 0 0011.7 5.724l4.264 4.262a.9.9 0 101.272-1.272l-16.2-16.2a.9.9 0 10-1.272 1.272L5.027 6.3A7.222 7.222 0 003.5 10.75zm1.8 0a5.45 5.45 0 008.615 4.438L6.312 7.585A5.422 5.422 0 005.3 10.75z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SearchSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24SearchSlashOutline: FC<Icon24SearchSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24SearchSlashOutline as any).mountIcon = mountIcon;

export default Icon24SearchSlashOutline;
