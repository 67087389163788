import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'discount_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="discount_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.713 3.072a3.18 3.18 0 014.574 0l.225.233c.3.31.725.464 1.153.42l.302-.032a3.2 3.2 0 013.525 2.958l.022.303c.03.43.256.821.613 1.062l.252.17a3.2 3.2 0 01.799 4.532l-.178.246a1.4 1.4 0 00-.213 1.208l.083.292a3.2 3.2 0 01-2.301 3.986l-.295.073a1.4 1.4 0 00-.94.789l-.123.277a3.2 3.2 0 01-4.325 1.574l-.273-.133a1.4 1.4 0 00-1.226 0l-.273.133a3.2 3.2 0 01-4.325-1.574l-.124-.277a1.4 1.4 0 00-.94-.789l-.294-.073a3.2 3.2 0 01-2.301-3.986l.084-.292A1.4 1.4 0 003 12.964l-.179-.246a3.2 3.2 0 01.8-4.532l.251-.17a1.4 1.4 0 00.613-1.062l.022-.303a3.2 3.2 0 013.525-2.958l.302.031a1.4 1.4 0 001.153-.42l.225-.232zm3.28 1.25a1.38 1.38 0 00-1.985 0l-.226.233a3.2 3.2 0 01-2.634.96l-.302-.032a1.4 1.4 0 00-1.543 1.294l-.021.303A3.2 3.2 0 014.88 9.508l-.252.17a1.4 1.4 0 00-.35 1.983l.18.245a3.2 3.2 0 01.486 2.762l-.084.292a1.4 1.4 0 001.007 1.743l.295.074a3.2 3.2 0 012.148 1.802l.123.277a1.4 1.4 0 001.892.689l.273-.133a3.2 3.2 0 012.804 0l.273.133a1.4 1.4 0 001.892-.689l.123-.277a3.2 3.2 0 012.148-1.802l.295-.074a1.4 1.4 0 001.007-1.743l-.084-.292a3.2 3.2 0 01.487-2.762l.178-.245a1.4 1.4 0 00-.35-1.983l-.25-.17a3.2 3.2 0 01-1.403-2.428l-.021-.303a1.4 1.4 0 00-1.543-1.294l-.302.031a3.2 3.2 0 01-2.634-.959l-.226-.233z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M9.45 10.225v-.55a.375.375 0 00-.75 0v.55a.375.375 0 00.75 0zM9.075 8C8.15 8 7.4 8.75 7.4 9.675v.55a1.675 1.675 0 103.35 0v-.55C10.75 8.75 10 8 9.075 8zM15.3 14.324v-.55a.375.375 0 00-.75 0v.55a.375.375 0 00.75 0zm-.375-2.225c-.925 0-1.675.75-1.675 1.675v.55a1.675 1.675 0 103.35 0v-.55c0-.925-.75-1.675-1.675-1.675zM14.621 8.086a.675.675 0 01.194.935l-4.4 6.7a.675.675 0 11-1.13-.742l4.401-6.7a.675.675 0 01.935-.193z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DiscountOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DiscountOutline: FC<Icon24DiscountOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DiscountOutline as any).mountIcon = mountIcon;

export default Icon24DiscountOutline;
