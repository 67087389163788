import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'gift_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="gift_16"><path d="M7.5 9.438v4H5.423c-.669 0-.911-.07-1.156-.2a1.363 1.363 0 01-.567-.567c-.13-.244-.2-.487-.2-1.156V9.438zm5 0v2.077c0 .67-.07.911-.2 1.156-.131.244-.323.436-.567.567-.223.119-.443.187-.984.199l-.172.001H8.5v-4zm-5-4v3H3.461c-.334 0-.455-.034-.577-.1a.682.682 0 01-.284-.283c-.065-.123-.1-.244-.1-.578v-.615c0-.343.058-.58.167-.784.109-.204.269-.364.472-.473.204-.109.442-.167.784-.167zm4.577 0c.342 0 .58.058.783.167s.364.27.473.473c.09.17.146.363.162.62l.005.164v.615c0 .334-.035.455-.1.578a.681.681 0 01-.284.283c-.104.056-.209.09-.447.098l-.13.002H8.5v-3zM8 5.07l.001-.119C8.124 1.661 10.45.888 11.5 1.938c1.1 1.1.2 3.6-3.5 3.5-3.7.1-4.6-2.4-3.5-3.5C5.563.876 7.931 1.68 8.001 5.07z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16GiftProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Gift: FC<Icon16GiftProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Gift as any).mountIcon = mountIcon;

export default Icon16Gift;
