import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'newsfeed_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="newsfeed_28"><path d="M19.98 3C22.2 3 24 4.8 24 7.02v13.96C24 23.2 22.2 25 19.98 25H8.02A4.02 4.02 0 014 20.98V7.02C4 4.8 5.8 3 8.02 3h11.96zm.01 2H8.01C6.9 5 6 5.9 6 7.01v13.98C6 22.1 6.9 23 8.01 23h11.98c1.11 0 2.01-.9 2.01-2.01V7.01C22 5.9 21.1 5 19.99 5zm-.995 6c.555 0 1.005.45 1.005 1.005v7.99C20 20.55 19.55 21 18.995 21h-9.99C8.45 21 8 20.55 8 19.995v-7.99C8 11.45 8.45 11 9.005 11h9.99zM15 7a1 1 0 110 2H9a1 1 0 010-2h6z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28NewsfeedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Newsfeed: FC<Icon28NewsfeedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Newsfeed as any).mountIcon = mountIcon;

export default Icon28Newsfeed;
