import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'report_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="report_outline_24"><path clip-rule="evenodd" d="M8.863 2.1h6.274c.674-.002 1.2.211 1.683.697l4.383 4.383c.478.476.699.998.697 1.683v6.275c.002.684-.22 1.206-.697 1.682l-4.383 4.383c-.476.478-.997.699-1.683.697H8.863c-.685.002-1.207-.22-1.683-.697L2.797 16.82c-.478-.476-.699-.998-.697-1.683V8.863c-.002-.685.22-1.207.697-1.683L7.18 2.797c.484-.486 1.009-.699 1.683-.697zm-.445 2.005L4.105 8.418c-.18.18-.205.242-.205.495v6.174c0 .25.025.316.205.495l4.313 4.313c.179.179.24.205.495.205h6.174c.253 0 .316-.026.495-.205l4.313-4.313c.18-.18.205-.245.205-.495V8.913c0-.254-.026-.316-.205-.495l-4.313-4.313c-.18-.18-.242-.205-.495-.205H8.913c-.254 0-.319.029-.495.205zM13 16a1 1 0 11-2 0 1 1 0 012 0zm-.1-8.1a.9.9 0 10-1.8 0v5.2a.9.9 0 101.8 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ReportOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ReportOutline: FC<Icon24ReportOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ReportOutline as any).mountIcon = mountIcon;

export default Icon24ReportOutline;
